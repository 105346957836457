import React from 'react';
import { Spin } from 'antd';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            id: props.match.params.id,
        }
    }
    componentDidMount() {
        if(this.isMobile()){
            window.location.href = '/wap#/casedetail/'+this.state.id;
            return;
        }
        this.getDetail()
    }
    isMobile() {
		return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
	}
    getDetail(){
        global.Ajax.hhtc_request('getCaseDetail', { id: this.state.id }).then(res => {
            if (res.code == 1) {
                this.setState({
                    caseInfo: res.data
                },()=>{
                    setTimeout(() => {
                        this.wrapRef.scrollTop=0;
                    }, 10);
                })
                
            }
        })
        global.Ajax.hhtc_request('getAboutCase',{id: this.state.id}).then(res=>{
            if(res.code==1){
                this.setState({
                    data: res.data
                })
            }
        })
    }
    render() {
        const { caseInfo } = this.state;
        return (
            <React.Fragment>
                {!caseInfo && <div className="loading-container" style={{background: '#fff',marginTop: 100}}>
                    <Spin tip='疯狂加载中...' />
                </div>}
                {caseInfo && <div style={{ minHeight: '100vh', _height: '100vh', background: '#f8f8f8', overflow: 'hidden' }}>
                    <div className='fixed'>
                        <div className='head'>
                            <img alt='' title='鸿鹄科技' src={require('../imgs/logoh.png').default} className='logo' />
                            <h1>·案例中心</h1>
                            <p className='back' title='返回首页' onClick={() => {
                                this.props.history.push('/home')
                            }}></p>
                        </div>
                    </div>
                    <div className='detailbox' ref={dom=>this.wrapRef=dom}>
                        <div className='box'>
                            <h1>{caseInfo.name}</h1>
                            {/* 发布时间：2020-1-30 类型：充电宝  */}
                            <p className='time'>浏览次数：{caseInfo.readNum}次</p>
                            <div className='wrap'>
                                <p className='desc'>{caseInfo.desc}</p>
                                <img alt='' src={caseInfo.content} className='pic' />
                            </div>
                        </div>
                        <div className='caselist'>
                            {this.state.data.map((item, index) => (
                                <div key={String(index)} className='row' onClick={() => {
                                    this.setState({
                                        caseInfo: undefined,
                                        id: item.id,
                                    },()=>{
                                        this.getDetail()
                                    })
                                }}>
                                    <img alt='' src={item.img} className='img' />
                                    <div className='mask'>
                                        <img alt='' src={require('../imgs/search.png').default} className='search' />
                                    </div>
                                    <div className='wrap'>
                                        <div className='flex'>
                                            < h1>{item.name}</h1>
                                            <img alt='' src={require('../imgs/jt.png').default} />
                                        </div>
                                        <p className='desc'>{item.desc}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>}
            </React.Fragment>
        )
    }
}
