import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Spin,Empty } from 'antd';

export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
			loading: false,
			hasMore: true,
			page: 1,
			size: 20,
			type: '',
			typeList: []
		}
	}
	isMobile() {
		return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
	}
	componentDidMount() {
		if(this.isMobile()){
			window.location.href = '/wap#/case';
            return;
		}
		global.Ajax.hhtc_request('getCaseType',{}).then(res=>{
			if(res.code==1){
				let typeList=[{
					id: '',
					name: '全部'
				}]

				this.setState({
					typeList: typeList.concat(res.data)
				})
			}
		})
		this.getList()
	}
	onRefresh() {
		this.setState({
			loading: true,
			hasMore: true,
			page: 1,
			data: []
		}, () => {
			this.getList()
		})
	}
	getList() {
		// 模拟请求数据---正式请求数据不要这个
		// setTimeout(() => {
		// 	var hasMore = true;
		// 	this.state.page++;
		// 	this.setState({
		// 		page: this.state.page,
		// 		data: this.state.data.concat([1, 1, 1, 1, 1, 1]),
		// 		loading: false,
		// 		hasMore: hasMore
		// 	})
		// }, 2000);
		// return
		var req = {
			page: this.state.page,
			size: this.state.size,
			type: this.state.type,
		}
		global.Ajax.hhtc_request('getCaseByType', req).then(res => {
			if (res.code == 1) {
				var hasMore;
				if (res.data.length == this.state.size) {
					hasMore = true
				} else {
					hasMore = false
				}
				this.state.page++;
				this.setState({
					page: this.state.page,
					data: this.state.data.concat(res.data.datas),
					loading: false,
					hasMore: hasMore
				})
			}
		})
	}
	handleInfiniteOnLoad = () => {
		this.setState({
			loading: true,
		}, () => {
			this.getList()
		});
	};
	render() {
		return (
			<div style={{ minHeight: '100vh', _height: '100vh', background: '#f8f8f8' }}>
				<div className='fixed'>
					<div className='head'>
						<img alt='' title='鸿鹄科技' src={require('../imgs/logoh.png').default} className='logo' />
						<h1>·案例中心</h1>
						<p className='back' title='返回首页' onClick={()=>{
                            this.props.history.push('/home')
                        }}></p>
					</div>
					<div className='casetype'>
						{this.state.typeList.map((item,index)=>(
							<p className={this.state.type==item.id?'active':''} onClick={() => {
								this.setState({
									type: item.id,
								}, () => {
									this.onRefresh()
								})
							}}>{item.name}</p>
						))}
					</div>
				</div>
				<div className='casebox'>
					<InfiniteScroll
						initialLoad={false}
						pageStart={0}
						loadMore={this.handleInfiniteOnLoad}
						hasMore={!this.state.loading && this.state.hasMore}
						useWindow={false}
					>
						<div className='caselist'>
							{this.state.data.map((item, index) => (
								<div key={String(index)} className='row' onClick={()=>{
									this.props.history.push(`/casedetail/${item.id}`)
								}}>
									<img alt='' src={item.img} className='img' />
									<div className='mask'>
										<img alt='' src={require('../imgs/search.png').default} className='search' />
									</div>
									<div className='wrap'>
										<div className='flex'>
											< h1>{item.name}</h1>
											<img alt='' src={require('../imgs/jt.png').default} />
										</div>
										<p className='desc'>{item.desc}</p>
									</div>
								</div>
							))}
						</div>
					</InfiniteScroll>
					{this.state.loading && this.state.hasMore && (
						<div className="loading-container">
							<Spin tip='疯狂加载中...' />
						</div>
					)}
					{!this.state.hasMore&&this.state.data.length==0&&<Empty style={{marginTop: 100}} />}
					{!this.state.hasMore&&this.state.data.length>0&&<p style={{padding: '20px 0 30px',color: '#999',textAlign: 'center'}}>已经到底了~</p>}
				</div>
			</div>
		)
	}
}
